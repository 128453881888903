<template>
    <div id="installation-step-four" class="div-height">
        <v-card height="100%">
            <v-card-title>
                <v-icon class="pointer" @click="previousStep()">mdi-arrow-left</v-icon>
                <span class="ml-1">{{ $t('step_4.title') }} {{ equipment_name }}</span>
            </v-card-title>

            <v-card-text>
                <!-- IF GENERIC -->
                <template v-if="asset_type.id_module">
                    <edit-asset-of-asset-module-form-component
                        :asset_type="asset_type"
                        :id_device="id_device"
                        :id_professional="id_professional"
                        :uid_professional="uid_professional"
                        :user="user"
                        class="mb-4"
                        v-on:goToResult="$emit('goToResult')"
                        v-on:previousStep="previousStep"
                    />
                </template>

                <template v-else-if="asset_installation_implemented.includes(asset_type.key)">
                    <v-form ref="form">
                        <asset-domicile ref="assetDomicile" :user="user" />

                        <p>
                            <strong>{{ $t('asset_specification.equipment_information') }}</strong>
                        </p>

                        <v-text-field
                            v-model="asset_name"
                            :label="$t('asset_specification.name_your_equipment')"
                            :rules="[(l) => !!l || $t('asset_specification.name_required')]"
                            class="rounded-0"
                            outlined
                        />

                        <v-text-field
                            v-model="asset_description"
                            :label="$t('asset_specification.description')"
                            class="rounded-0"
                            outlined
                        />

                        <template v-if="asset_type.key === 'cuve'">
                            <tank-type ref="tankType" />
                        </template>
                        <template v-else-if="asset_type.key === 'silo'">
                            <silo-type ref="siloType" />
                        </template>

                        <asset-specification
                            ref="assetSpecification"
                            :asset_type="asset_type.key"
                            :device_number="device_number"
                            :id_professional="id_professional"
                            class="pt-4"
                        />

                        <v-row>
                            <v-col>
                                <v-btn block class="white--text" color="grey" large @click="previousStep()">
                                    {{ $t('back') }}
                                </v-btn>
                            </v-col>

                            <v-col>
                                <v-btn :loading="loading_begin_installation" block color="primary" large @click="prepareInstallDevice()">
                                    {{ $t('validate') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </template>

                <template v-else>
                    <v-alert border="left" color="orange" colored-border elevation="2" type="error">
                        <h4>{{ $t('step_4.no_installation_for_this_type') }}</h4>
                    </v-alert>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import AssetSpecification from './installation-step-4/asset-specification'
import AssetDomicile from './installation-step-4/asset-domicile/asset-domicile'
import TankType from './installation-step-4/asset-type/tank-type'
import SiloType from './installation-step-4/asset-type/silo-type'
import EditAssetOfAssetModuleFormComponent from '@/components/installation-step/installation-step-4/generic/EditAssetOfAssetModuleFormComponent.vue'

export default {
    name: 'InstallationStepFourComponent',
    components: {
        EditAssetOfAssetModuleFormComponent,
        AssetSpecification,
        AssetDomicile,
        TankType,
        SiloType,
    },
    data() {
        return {
            asset_installation_implemented: ['cuve', 'silo', 'generic'],
            asset: {
                asset_type: '',
                existing_address: false,
                id_domicile: null,
                domicile: null,
                domicile_name: '',
                asset_name: '',
                description: '',
                offset: null,
                id_liquid_type: null,
                id_localisation_type: null,
                index_monitoring_type: null,
                index_screw_number: null,
            },
            asset_name: '',
            asset_description: '',
            loading_begin_installation: false,
        }
    },
    props: {
        device_number: {
            type: String,
            required: false,
        },
        id_device: {
            type: Number,
            required: false,
        },
        id_professional: {
            type: Number,
            required: false,
        },
        uid_professional: {
            type: String,
            required: true,
        },
        asset_type: {
            type: Object,
            required: false,
        },
        user: {
            type: Object,
            required: false,
        },
    },
    computed: {
        equipment_name() {
            if (this.asset_type.id_module) {
              return this.$t('modules.' + this.asset_type.key)
            } else if (this.asset_type.key !== 'generic') {
                return this.$t('step_4.' + this.asset_type.key)
            } else if (this.asset_type.value === 'Ruche') {
                return this.$t('step_4.ruche')
            } else if (this.asset_type.value === "Machine d'usinage") {
                return this.$t('step_4.machining_machine')
            } else if (this.asset_type.value === 'Appartement') {
                return this.$t('step_4.appartement')
            }

            return this.$t('step_4.equipment')
        },
    },
    methods: {
        resetForm() {
            this.$refs.form.reset()
        },

        previousStep() {
            this.$emit('previousStep')
        },

        fillDataFromParamsToStep4() {
            const params = this.$store.getters['autofill/params']
            this.asset.asset_name = params.asset_name ?? ''
            this.asset.description = params.asset_description ?? ''
        },

        prepareInstallDevice() {
            if (this.$refs.form.validate()) {
                if (this.$refs.assetDomicile.domicile === null) {
                    this.showSnackbar('warning', this.$t('step_4.error_domicile'))
                } else {
                    if (this.$refs.assetDomicile.value_type_address === 1) {
                        this.asset.existing_address = true
                    }

                    if (this.asset_type.key === 'cuve') {
                        this.asset = this.$refs.tankType.tank_specification
                    } else if (this.asset_type.key === 'silo') {
                        this.asset = this.$refs.siloType.silo_specification
                    } else if (this.asset_type.key === 'generic') {
                        this.asset = this.$refs.genericType.generic_specification
                    }

                    this.asset.asset_name = this.asset_name
                    this.asset.description = this.asset_description

                    this.asset.id_domicile = this.$refs.assetDomicile.id_domicile
                    this.asset.domicile_name = this.$refs.assetDomicile.domicile_name
                    this.asset.domicile = this.$refs.assetDomicile.domicile

                    this.asset.offset = this.$refs.assetSpecification.offset
                    this.asset.id_liquid_type = this.$refs.assetSpecification.id_liquid_type
                    this.asset.id_localisation_type = this.$refs.assetSpecification.id_localisation_type
                    this.asset.index_monitoring_type = this.$refs.assetSpecification.index_monitoring_type
                    this.asset.index_screw_number = this.$refs.assetSpecification.index_screw_number

                    this.installDevice()
                }
            } else {
                this.showSnackbar('warning', this.$t('fill_all_fields'))
            }
        },

        installDevice() {
            this.loading_begin_installation = true

            this.axios
                .post('/api/v1/installation/device', {
                    asset: this.asset,
                    device_number: this.device_number,
                    id_professional: this.id_professional,
                    id_referential: this.asset_type.id_referential,
                    id_user: this.user ? this.user.id_user : null,
                })
                .then(() => {
                    this.$emit('goToResult')
                })
                .catch((error) => {
                    this.manageError('error', this.$t('step_4.error_during_installation'), error)
                })
                .finally(() => {
                    this.loading_begin_installation = false
                })
        },
    },
    created() {
        this.fillDataFromParamsToStep4()
    },
}
</script>

<style>
#installation-step-four {
    max-width: 1000px;
    margin: auto;
}
</style>
